<template>
  <div id="con">
    <el-container style="height: 100vh; border: 1px solid #eee">
      <!-- </el-aside> -->
      <el-container style="border: 1px solid #eee">
        <el-header class="title-con">
          <!-- <div class="title-color">{{changeTitle}}</div> -->
          <div class="title-color">练习内容：{{ changeTitle }}</div>
          <!-- <el-button type="primary" plain style="float:right;margin-top: -43px;" onclick="location.reload();">
            重新加载</el-button> -->
        </el-header>
        <el-main
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
        >
          <iframe class="iframe-style" :src="iframeSrc"></iframe>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
export default {
  data(){
    return {
        changeTitle: "",
    qid: "",
    userid: "",
    jobid: "",
    iframeSrc: "",
    loading: true,
    timeE: null,
    timeEnd: 0,
    }
   
  },
  components: {},
  created() {
    window.addEventListener("beforeunload", this.leaveConfirm);
  },

  mounted() {
    this.changeTitle = this.$route.query.title;
    this.qid = this.$route.query.qid;
    this.userid = this.$route.query.userid;
    this.jobid =this.$route.query.jobid;
    this.id = this.$route.query.id;

    this.iframeSrc = `https://scratch.educg.net?TcTylianxi=true&qid=${this.qid}&userid=${this.userid}&jobid=${this.jobid}`;
    setTimeout(() => {
      this.loading = false;
    }, 500);
    this.timeE = setInterval(() => {
      this.timeEnd++;
    }, 1000);
  },
  methods: {
    leaveConfirm(e) {
      this.timeLong(this.timeEnd, this.id);
    },
    getQueryString(name) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      if (r != null) return decodeURI(r[2]);
      return null;
    },
    //时长
    timeLong(time, id) {
    
      var _this = this;
 
      let data = {
        op: "recordlog",
        type:'练习',
        time:time,
        _id:id
      };
     
      this.$ajax
        .post(this.url + "/student/courses.do", _this.Qs.stringify(data))

        .then(function (response) {})
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
<style scoped>
body,
div,
ul,
li,
p {
  padding: 0;
  margin: 0;
  list-style: none;
}
#con{
    text-align: left;
}

.left-all {
  background: #f5f5f5;
}

.title-con {
  width: 100%;
  padding: 10px;
  background-color: #f7f8dd !important;

  color: #1890ff !important;
}

.title-all {
  text-align: center;
  padding: 10px 0;
  color: #1890ff;
}

.el-header {
  background-color: #efefef;
  color: #fff;
  line-height: 60px;
}

.el-aside {
  color: #333;
}

.bigTitle {
  text-align: center;
  border-bottom: 1px solid #eee;
  padding: 31px 10px;
}

.title-color {
  line-height: 42px;
  /* color: #fff; */
}

.el-main {
  padding: 0;
}

.iframe-style {
  width: 100%;
  height: calc(100vh - 60px);
  border: none;
}
</style>